import React, { useState } from 'react';
import './Navbar.css'
import { MdOutlineExpandMore } from "react-icons/md";
import { Link } from 'react-router-dom';
import { AiOutlineMenu } from "react-icons/ai";
import { MdClear } from "react-icons/md";

const Navbar = () => {

    const [openMenu, setOpenMenu] = useState(false);

    return (
        <div className="navbar">
            <div className="navbarContainer">
                <div className="logo">
                    <Link to="/">
                        <img src="/assets/logo.png" alt="ayalla-logo" />
                    </Link>
                </div>

                <div className="menu">
                    <div className="links">
                        <Link className='link' to="/">
                            <span className='linkText'>Home</span>
                        </Link>

                        <Link to="/about-us" className='link'>
                            <span className='linkText'>About</span>
                        </Link>

                        <span className='serviceDropDown linkText'>
                            Our Hotels
                            <MdOutlineExpandMore size={25} />
                            <div className='serviceDropMenu'>
                                <Link className='link' to="/yenagoa-hotel">
                                    <span className='serviceText'>Yenagoa Rooms</span>
                                </Link>

                                {/* <Link className="link" to="/abuja-hotel">
                                    <span className='serviceText'>Abuja Rooms</span>
                                </Link> */}

                                <Link className="link" to="/ayalla-vip-business-apartment">
                                    <span className='serviceText'>Ayalla VIP Suites</span>
                                </Link>

                            </div>
                        </span>

                        <span className='serviceDropDown linkText'>
                            Recreational Services
                            <MdOutlineExpandMore size={25} />
                            <div className='serviceDropMenu'>
                                <Link className='link' to="/restaurant">
                                    <span className='serviceText'>
                                        Restaurant
                                    </span>
                                </Link>

                                <Link className='link' to="/swimming-pool">
                                    <span className='serviceText'>
                                        Swimming Pool
                                    </span>
                                </Link>

                                <Link className='link' to="/bar">
                                    <span className='serviceText'>Ayalla Bar</span>
                                </Link>

                                <Link className='link' to="/conference">
                                    <span className='serviceText'>Conference Room</span>
                                </Link>

                                <Link className='link' to="/gym">
                                    <span className='serviceText'>Ayalla Gym</span>
                                </Link>

                                <Link className='link' to="/computer-centre">
                                    <span className='serviceText'>Computer Centre</span>
                                </Link>

                                <Link className="link" to="/event-halls">
                                    <span className='serviceText'>Event Hall</span>
                                </Link>

                            </div>

                        </span>

                        <Link to="tel:+2349071111218" className='link'>
                            <button> +23490-7111-1218 </button>
                        </Link>
                    </div>
                </div>

                {/* menu button */}
                <div className='mobileBtn' onClick={() => setOpenMenu(true)}>
                    <AiOutlineMenu size={25} color={"#333333"} />
                </div>

                {openMenu && (
                    <div className='mobileMenu'>
                        {/* close mobile menu btn */}
                        <div className='close-btn' onClick={() => setOpenMenu(false)}>
                            <MdClear size={30} />
                        </div>
                        <div className='mobile-link-text'>
                            <Link className='link' to="/">
                                <span>Home</span>
                            </Link>

                            <Link to="/about-us" className='link'>
                                <span>About Us</span>
                            </Link>

                            <Link className='link' to="/yenagoa-hotel">
                                <span>Yenagoa Rooms</span>
                            </Link>

                            {/* <Link className="link" to="/abuja-hotel">
                                <span>Abuja Rooms</span>
                            </Link> */}

                            <Link className="link" to="/ayalla-vip-business-apartment">
                                <span>Ayalla VIP Suites</span>
                            </Link>

                             <h3>Recreational Services</h3>   
                            {/* recreational services */}
                            <Link className='link' to="/restaurant">
                                <span className='serviceText'>
                                    Restaurant
                                </span>
                            </Link>

                            <Link className='link' to="/swimming-pool">
                                <span className='serviceText'>
                                    Swimming Pool
                                </span>
                            </Link>

                            <Link className='link' to="/bar">
                                <span className='serviceText'>Ayalla Bar</span>
                            </Link>

                            <Link className='link' to="/conference">
                                <span className='serviceText'>Conference Room</span>
                            </Link>

                            <Link className='link' to="/gym">
                                <span className='serviceText'>Ayalla Gym</span>
                            </Link>

                            <Link className='link' to="/computer-centre">
                                <span className='serviceText'>Computer Centre</span>
                            </Link>

                            <Link className="link" to="/event-halls">
                                <span className='serviceText'>Event Hall</span>
                            </Link>

                            {/* end of recreational services */}
                            <Link to="tel:+2349071111218" className='link'>
                            <button> +23490-7111-1218 </button>
                            </Link>


                        </div>

                    </div>
                )}

            </div>

        </div>
    )
}

export default Navbar;