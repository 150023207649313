import React from 'react';
import Navbar from '../../../components/Navbar/Navbar';

import './Restaurant.css';
import Footer from '../../../components/Footer/Footer';

import { Helmet } from 'react-helmet-async';

const Restaurant = () => {
    return (
        <>

            <Helmet>
            <title>Restaurant - Ayalla Hotels Limited</title>
            <meta name="description" content="Enjoy a delightful culinary experience at Ayalla Hotels' restaurant. Savor international and local cuisines." />
            <meta name="keywords" content="Ayalla Restaurant, fine dining Yenagoa, international cuisine, local dishes" />
            <meta property="og:title" content="Restaurant - Ayalla Hotels Limited" />
            <meta property="og:description" content="Taste the finest cuisines at Ayalla Hotels' restaurant. A treat for every palate." />
            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://ayallahotels.com/restaurant" />
            </Helmet>

            <Navbar />

            <div className='restaurant'>
                <h1>Ayalla Restaurant</h1>
            </div>

            <div className='resWrapper'>
                <img src="/assets/serviceImages/rest.jpg" alt="ayalla restaurant" />

                <div className='resDetails'>
                    <span>Details</span>
                    <p>
                        We serve local as well as Chinese, Indian and other assorted dishes including KKF, national and continental meals.
                    </p>
                </div>


            </div>
            <Footer />

        </>
    )
}

export default Restaurant;