import React from 'react';
import Navbar from '../../../components/Navbar/Navbar';

import './Bar.css';
import Footer from '../../../components/Footer/Footer';

import { Helmet } from 'react-helmet-async';

const Bar = () => {
    return (
        <>

        <Helmet>
        <title>Ayalla Bar - Premium Drinks and Cocktails</title>
        <meta name="description" content="Visit Ayalla Bar for premium drinks, cocktails, and a relaxing ambiance. The perfect spot to unwind." />
        <meta name="keywords" content="Ayalla Bar, cocktails Yenagoa, premium drinks, luxury bar" />
        <meta property="og:title" content="Ayalla Bar - Premium Drinks and Cocktails" />
        <meta property="og:description" content="Sip on premium cocktails at Ayalla Bar. A luxurious escape in Yenagoa." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://ayallahotels.com/bar" />
        </Helmet>


            <Navbar />

            <div className='bar'>
                <h1>Ayalla Bar</h1>
            </div>

            <div className='barWrapper'>
                <img src="/assets/serviceImages/bar.jpeg" alt="ayalla bar" />

                <div className='barDetails'>
                    <span>Details</span>
                    <p>
                        The hotel bar serves all ranges of wine such as Whisky, Brandy, and Alcohol etc.
                    </p>
                </div>


            </div>
            <Footer />

        </>
    )
}

export default Bar;