import React from 'react';
import Navbar from '../../../components/Navbar/Navbar';

import './ConferenceRoom.css';
import Footer from '../../../components/Footer/Footer';

import { Helmet } from 'react-helmet-async';

const ConferenceRoom = () => {
    return (
        <>

        <Helmet>
        <title>Conference Room - Ayalla Hotels Limited</title>
        <meta name="description" content="Book our state-of-the-art conference rooms for business meetings, seminars, and corporate events at Ayalla Hotels Limited." />
        <meta name="keywords" content="Conference Room, business meetings, Ayalla Hotels, corporate events" />
        <meta property="og:title" content="Conference Room - Ayalla Hotels Limited" />
        <meta property="og:description" content="Host your business meetings in Ayalla Hotels' premium conference rooms." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://ayallahotels.com/conference" />
        </Helmet>

            <Navbar />

            <div className='meeting'>
                <h1>Ayalla Meeting Room</h1>
            </div>

            <div className='meetingWrapper'>
                <img src="/assets/serviceImages/meetingroom.jpg" alt="ayalla meeting room" />

                <div className='meetingDetails'>
                    <span>Details</span>
                    <p>
                        Our meeting room can accommodate large number of people and provide all the necessary assistance in terms of your technology requirements, food & beverage preferences.
                    </p>
                    <p>
                        A range of audio-visual equipment is available including LCD projectors, overhead projectors, PA systems as well as internet and Wi-Fi connections in all the meeting room types.
                    </p>
                </div>


            </div>

            <Footer />
        </>
    )
}

export default ConferenceRoom;