import React from 'react';
import Navbar from '../../../components/Navbar/Navbar';

import './EventHall.css';
import Footer from '../../../components/Footer/Footer';

import { Helmet } from 'react-helmet-async';

const EventHall = () => {
    return (
        <>

        <Helmet>
        <title>Events - Ayalla Hotels Limited</title>
        <meta name="description" content="Host your special events at Ayalla Hotels Limited. From weddings to corporate meetings, we provide elegant venues." />
        <meta name="keywords" content="Event venues, Ayalla Hotels, weddings, conferences, meetings" />
        <meta property="og:title" content="Events - Ayalla Hotels Limited" />
        <meta property="og:description" content="Make your events unforgettable at Ayalla Hotels. Book now for a seamless experience." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://ayallahotels.com/event-halls" />
        </Helmet>

            <Navbar />

            <div className='event'>
                <h1>Ayalla Event Hall</h1>
            </div>

            <div className='eventWrapper'>
                <img src="/assets/serviceImages/eventhall.jpg" alt="ayalla event hall" />

                <div className='eventDetails'>
                    <span>Details</span>
                    <p>
                        Our event hall are very spacious and well furnished ideal for your seminars, Presentations and Wedding.
                    </p>
                </div>


            </div>
            <Footer />

        </>
    )
}

export default EventHall;