import React from 'react';
import Navbar from '../../../components/Navbar/Navbar';

import './ComputerCentre.css';
import Footer from '../../../components/Footer/Footer';

import { Helmet } from 'react-helmet-async';

const ComputerCentre = () => {
    return (
        <>

        <Helmet>
        <title>Computer Centre - Ayalla Hotels Limited</title>
        <meta name="description" content="Access high-tech computer facilities at Ayalla Hotels Limited. Ideal for business meetings and personal use." />
        <meta name="keywords" content="Computer Centre, tech facilities, Ayalla Hotels, business amenities" />
        <meta property="og:title" content="Computer Centre - Ayalla Hotels Limited" />
        <meta property="og:description" content="Modern computer facilities for seamless business operations at Ayalla Hotels." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://ayallahotels.com/computer-centre" />
        </Helmet>


            <Navbar />

            <div className='computer'>
                <h1>Ayalla Computer Centre</h1>
            </div>

            <div className='computerWrapper'>
                <img src="/assets/serviceImages/computer-centre.jpeg" alt="ayalla computer centre" />

                <div className='computerDetails'>
                    <span>Details</span>
                    <p>
                        Our Computer Centre offers the following services; Printing, Internet, Typesetting and much more.
                    </p>
                </div>


            </div>
            <Footer />

        </>
    )
}

export default ComputerCentre;