import React from 'react';
import Navbar from '../../../components/Navbar/Navbar';

import './SwimmingPool.css';
import Footer from '../../../components/Footer/Footer';

import { Helmet } from 'react-helmet-async';

const SwimmingPool = () => {
    return (
        <>
        <Helmet>
        <title>Swimming Pool - Ayalla Hotels Limited</title>
        <meta name="description" content="Relax and unwind at our state-of-the-art swimming pool at Ayalla Hotels Limited." />
        <meta name="keywords" content="Swimming Pool, Ayalla Hotels, luxury pool, relaxation" />
        <meta property="og:title" content="Swimming Pool - Ayalla Hotels Limited" />
        <meta property="og:description" content="Dive into comfort at the Ayalla Hotels swimming pool. Perfect for relaxation." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://ayallahotels.com/swimming-pool" />
        </Helmet>

            <Navbar />

            <div className='swim'>
                <h1>Ayalla Swimming Pool</h1>
            </div>

            <div className='swimWrapper'>
                <img src="/assets/serviceImages/swimming.jpg" alt="ayalla swimming pool" />

                <div className='swimDetails'>
                    <span>Details</span>
                    <p>
                        Additional perks of being an in-house guest is to enjoy a day or night time swim. Access to the pool for outside guests are at an additional cost.
                    </p>
                </div>


            </div>
            <Footer />

        </>
    )
}

export default SwimmingPool;