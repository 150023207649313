import React from 'react';
import './Home.css';
import Slide from '../../components/Slides/Slide';
import SearchForm from '../../components/SearchForm/SearchForm';
import Welcome from '../../components/WelcomeSection/Welcome';
import Hotel from '../../components/OurHotels/Hotels';
import Footer from '../../components/Footer/Footer';
import Navbar from '../../components/Navbar/Navbar';
import Gallery from '../../components/Gallery/Gallery';

import { Helmet } from 'react-helmet-async';

const Home = () => {
    return(
        <>
        <Helmet>
        <title>Ayalla Hotels Limited - Welcome to Luxury and Comfort</title>
        <meta name="description" content="Experience luxury and comfort at Ayalla Hotels Limited. Book your stay at the best hotel in Yenagoa, featuring premium rooms, restaurants, and more." />
        <meta name="keywords" content="Ayalla Hotels, Yenagoa Hotels, luxury hotels, premium rooms, best hotels in Yenagoa" />
        <meta property="og:title" content="Ayalla Hotels Limited - Welcome to Luxury and Comfort" />
        <meta property="og:description" content="Discover Ayalla Hotels Limited, your destination for a luxurious stay in Yenagoa. Perfect for business or leisure." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://ayallahotels.com/" />
        </Helmet>

        <div className="home"> 
            <Navbar />
            <div className="homeWrapper">
               <Slide/>
             
               <div className="searchContainer">
               <SearchForm />
               </div>
               <Welcome/>
               <Hotel/>
               <Gallery />
               <Footer/>
            </div>
        </div>
        </>
    )
}

export default Home;