import React, { useEffect, useState } from "react";

import "./AyallaVIP.css";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";
import newRequest from '../../../utils/newRequest';
import { Link } from "react-router-dom";
//import { MdOutlinePix } from "react-icons/md";

import { Helmet } from 'react-helmet-async';

const AyallaVIP = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const getVipRooms = async () => {
    setLoading(true);
    try {
      const {
        data: { data },
      } = await newRequest.get("/vip");
      setData(data);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    getVipRooms();
  }, []);

  return (
    <>

<Helmet>
  <title>Ayalla VIP Rooms - Ultimate Luxury</title>
  <meta name="description" content="Indulge in the ultimate luxury with Ayalla VIP Rooms. Exclusive amenities and unparalleled comfort await." />
  <meta name="keywords" content="VIP Rooms, Ayalla VIP, luxury suites, premium rooms Yenagoa" />
  <meta property="og:title" content="Ayalla VIP Rooms - Ultimate Luxury" />
  <meta property="og:description" content="Experience the finest hospitality in Ayalla VIP Rooms. Perfect for elite travelers seeking luxury." />
  <meta property="og:type" content="website" />
  <meta property="og:url" content="https://ayallahotels.com/ayalla-vip-business-apartment" />
</Helmet>


      <Navbar />

      <div className="yenagoa">
        <h1>Ayalla VIP Business Apartment</h1>
        {/* <p>
          Ayalla VIP Business Apartment is decorated and designed for your
          comfort and relaxation.
        </p> */}
      </div>

      <div className="yenagoaWrapper">
        {/* <h1>Coming Soon!</h1> */}
        <div className="vip-card-container">
          {loading ? (
            <h2>loading data...</h2>
          ) : (
            data?.map((item, index) => (
                <div key={index} className="vip-card">
                    <img src={item.roomimage} alt="" />
                    <div className="vip-card-body">
                        <span>{item.roomname}</span>
                        <p> ₦ {item.roomprice.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}/ Per Night</p>
                    </div>

                    <Link to={`/booking/${item._id}`} className="link">
                        <button>BOOK NOW</button>
                    </Link>
                </div>
            ))
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AyallaVIP;
